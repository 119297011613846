import React from "react";
import path from "path";
import TitleBar from './TitleBar';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const ComicPanel = (props) => {
    
    var {koganusanIndex} = props;
    console.log(koganusanIndex[koganusanIndex.length-1])

    const imageHost = "images.beigebeard.com/"
    var imageUrl = '';
    var index = 0;
    var titleBarProps = {};

    if (koganusanIndex) {
        if (props.location.pathname == "/" || props.location.pathname == "/koganusan") {
            index = koganusanIndex.length-1
            imageUrl = path.join(imageHost,koganusanIndex[index].split("images")[1])
        } else {
            index = koganusanIndex.indexOf(path.join("images",props.location.pathname+'.png'))
            imageUrl = path.join(imageHost,props.location.pathname+'.png')
        }

        const indexTrimmed = koganusanIndex.map(s => s.trim().split('images')[1].split('.png')[0])

        titleBarProps = {
            next: indexTrimmed[Math.min(index + 1,koganusanIndex.length-1)],
            prev: indexTrimmed[Math.max(index - 1,0)],
            start: indexTrimmed[0],
            end: indexTrimmed[koganusanIndex.length-1]
        }
    }

    return (
        <div className="App">
            <TitleBar {...titleBarProps} />
                <div className="App"></div>
                <img src={"https://"+imageUrl} className="App-logo" alt="koganusan" />
            <TitleBar {...titleBarProps} />
        </div>)
    };
export default ComicPanel;
