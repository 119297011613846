import React from "react";
import logo from './logo.svg';
import './App.css';
import fetch from 'node-fetch';
import ComicPanel from './ComicPanel';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      koganusanIndex: "",
      previous: "",
      next: "",
      start: "",
      end: "",
      body: ""
    };
  }
  
  componentDidMount() {
    fetch('https://images.beigebeard.com/koganusan-index.txt')
      .then(res => res.text())
      .then(body => {
        this.setState({body});
        const koganusanIndex = body.split(",").filter(a => a !== ' ').filter(a => a !== '').map(s => s.trim()).sort(function(a, b){return b-a})
        this.setState({koganusanIndex});
      });
  }
    //var res = await fetch('https://images.beigebeard.com/koganusan-index.txt');
    //var text = await res.text();

    render() {
      //this.getIndex();
      
      return (
      <Router>
        <Switch>
          {/*<Route path="/koganusan" component={ComicPanel}/>*/}
          <Route path="/" render={(props) => (<ComicPanel {...props} koganusanIndex={this.state.koganusanIndex}/>)}/>
        </Switch>
        <footer className="pagefooter" align="right">
          <Link to={'/archive'} >A<span style={{color:"white"}}>RCHIVE</span></Link>
          <Link to={'/about'} >A<span style={{color:"white"}}>BOUT</span></Link>
        </footer>
      </Router>
      );
    }
    
    
}

export default App;
